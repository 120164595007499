<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        user-role="procurer"
      />
      <div class="q-pa-md">
        <pro-form-render
          mode="Edit"
          :code="WorkflowCode"
          :form-data-promise="formDataPromise()"
          @pro-form-submit="redirectToHcmDashboard('/Dashboard')"
        />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  components: { ProDocumentBreadcrumbs, ProFormRender },
  data() {
    return {
      breadcrumbs: [
        {
          name: "CustMenu.Com.Action.BatchUpdateClosingDate",
          icon: "el-icon-menu",
        },
      ],
      WorkflowCode: "A_BatchUpdateClosing",
    };
  },
  methods: {
    redirectToHcmDashboard(path) {
      // this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: path });
    },
    formDataPromise() {
      return new Promise((resolve) => {
        let obj = { params: this.$route.params };
        resolve(obj);
      });
    },
  },
};
</script>
